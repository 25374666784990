import React from "react"
import {Button, Card, Col, Container, CardDeck, Row} from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from "gatsby";

const Community = () => {
	return (
		<Layout>
			<SEO title="Join our Circles community"/>
			<Container>
				<h2 className="pt-3">Join our Circles community</h2>
				<Row>
					<Col md="6">
						<CardDeck>
							<Card className="mb-5">
								<Card.Body>
									<Card.Title>Join our Circles community</Card.Title>
									<Card.Text>
										Circles are small groups of women who come together for real talk and peer support—and right now we’re meeting virtually.
									</Card.Text>
								</Card.Body>
								<Card.Footer className="d-flex justify-content-between align-items-center">
									<small className="text-muted">Thu, 15 Dec 2020, 11:00</small>
									<Button as={Link} to="/sign-up">Register</Button>
								</Card.Footer>
							</Card>
						</CardDeck>
					</Col>
				</Row>

			</Container>
		</Layout>
	)
}

export default Community
